import React from "react";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

import { Link } from "gatsby";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Section } from "../components/bulma";

export default function ContentSection({ items }) {
  return (
    <>
      {items?.map(({ image, title, text, link }, i) => (
        <Section key={title}>
          <div className="columns is-vcentered">
            {Boolean(i % 2) && (
              <div className="column notification p-6">
                <p className="title is-size-2-desktop is-spaced">{title}</p>
                <p className="subtitle is-size-4-desktop">{text}</p>
                {link && (
                  <Link
                    to={link}
                    className="button is-outlined is-primary is-rounded"
                  >
                    Learn more
                  </Link>
                )}
              </div>
            )}
            <div className="column p-6 is-hidden-mobile">
              <figure className="image is-256x256">
                <PreviewCompatibleImage imageInfo={image} />
              </figure>
            </div>
            {Boolean(i % 2) || (
              <div className="column notification p-6">
                <p className="title is-size-2-desktop is-spaced">{title}</p>
                <p className="subtitle is-size-4-desktop">{text}</p>
                {link && (
                  <Link
                    to={link}
                    className="button is-outlined is-primary is-rounded"
                  >
                    Learn more
                  </Link>
                )}
              </div>
            )}
          </div>
        </Section>
      ))}
    </>
  );
}
