import React from "react";
// import PreviewCompatibleImage from './PreviewCompatibleImage';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Column, Columns } from "../components/bulma";

export default function Blurbs({ box, items }) {
  return (
    <Columns>
      {items?.map(item => (
        <div key={item.title} className="column">
          <Column>
            <div className="notification">
              <br />
              <p className="title is-size-3 is-spaced">{item.title}</p>
              <p className="subtitle is-size-5">{item.text}</p>
            </div>
          </Column>
        </div>
      ))}
    </Columns>
  );
}
