import React from "react";

import { openPopupWidget } from "react-calendly";

const url = "https://calendly.com/fastlabs/free-consultation";

const CTA = ({ partner }) => (
  <section className="hero is-primary is-bold box">
    <div className="hero-body">
      <div className="container">
        <div className="content has-text-centered">
          <h1 className="title">Talk to Expert</h1>
          <h2 className="subtitle">
            Book a free, no-obligation consultation today
          </h2>
          <br />
          <button
            onClick={() => openPopupWidget({ url })}
            className="button is-light is-large is-rounded"
          >
            <strong>Talk to Expert</strong>
          </button>
        </div>
      </div>
    </div>
  </section>
);

export default CTA;
